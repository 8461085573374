import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../../../components/Layout.js';
import Loading from '../../../components/Loading.js';
import Title from '../../../components/typography/Title.js';
import { loadEvent } from '../../../stores/Event/EventActions.js';
import { useEvent } from '../../../stores/Event/EventSelectors.js';
import Status, { isLoadingStatus } from '../../../stores/Status.js';
import { loadEventBanner } from '../../../stores/ExistingBanners/ExistingBannersActions.js';
import { useExistingBanner } from '../../../stores/ExistingBanners/ExistingBannersSelectors.js';
import ExistingBanner from '../../../components/existing-banners/ExistingBanner.js';
import BannerForm, { BannerTypes } from '../../../components/forms/BannerForm.js';

const EventBannerDelegate = () => {
  const dispatch = useDispatch();
  const { event } = useEvent();
  const { status } = useExistingBanner();

  useEffect(() => {
    if (event.id) {
      dispatch(loadEventBanner({ sportId: event.sport.id, eventId: event.id })).then();
    }
  }, [event.id, event.sport?.id, dispatch]);

  if (isLoadingStatus(status)) {
    return <Loading message="Loading existing banner..." />;
  }

  if (status === Status.OK) {
    return <ExistingBanner type={BannerTypes.Event} sport={event.sport} event={event} />;
  }

  return (
    <div>
      <p className="banner__description">
        There isn't an active banner for {event.name} at the moment. You can add one below...
      </p>
      <BannerForm
        type={BannerTypes.Event}
        sport={event.sport}
        eventId={event.id}
      />
    </div>
  );
};

const Event = ({ id }) => {
  const dispatch = useDispatch();
  const { status } = useEvent();

  useEffect(() => {
    if (id) {
      dispatch(loadEvent(id)).then();
    }
  }, [id, dispatch]);

  if (isLoadingStatus(status)) {
    return <Loading message="Loading event..." />;
  }

  if (status === Status.Error) {
    return null;
  }

  return <EventBannerDelegate />;
};

const withEventTitle = id => () => {
  const { event: { name } = {} } = useEvent(id);

  return name
    ? <Title black={name} blue="Banner" />
    : null;
};

export default function EventBanner({ params: { eventId } }) {
  if (!eventId) {
    return <Loading />;
  }

  return (
    <Layout TitleElement={withEventTitle(eventId)}>
      <Event id={eventId} />
    </Layout>
  );
};
